function isEmail(email) {
	var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	return regex.test(email);
}

$(document).ready(function(){

	$('#newsletterSubmit').click(function(){
		var tentId = $('#newsletterTentId').val();
		var email = $('#newsletterEmail').val();
		if (isEmail(email)){

			$.ajax({
				url: site_url+'/newsletter',
				type: 'post',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
				},
				data: {'tent':tentId, 'email':email}
			});

			$('.newsletter-sign-up-form').html('<p>Thank you for signing up!</p>');

		}
	});
	console.log('test');

  $('.news-post-preview').each(function(index) {
    if( [index + 1] % 3 == 1){
      $(this).css('clear','both');
    }
  });

});